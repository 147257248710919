<template>
  <div class="row mb-3">
    <div class="col-sm-12 card text-left py-3">
      <div class="row pb-2">
        <div class="col-sm-6"></div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-2">
              <span class="h4 font-weight-bold">Factura</span>
            </div>
            <div class="col-sm-3 pt-2">
              <div class="text-right">
                <label class="switch switch-success mr-3">
                  <input id="switchEstadoFactura"
                    type="checkbox"
                    :disabled="modoEdicion"
                    @change="onChangeEstadoFactura"
                    v-model="estado">
                  <span class="slider"></span>
                  <span>{{ labelActivos }}</span>
                </label>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-2 text-right pr-1">
                  <label class="col-form-label font-weight-bold">Fecha:</label>
                </div>
                <div class="col-sm-5 pr-0">
                  <date-picker
                    id="inputFecha"
                    :disabled="modoEdicion"
                    class="col-sm-12 px-0"
                    v-model="datepicker"
                    lang="es"
                    format="DD/MM/YYYY"
                    type="date"
                    value-type="date"
                  ></date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-7">
          <div class="row mb-2">
            <div class="col-sm-2 text-right">
              <label class="col-form-label">Paciente:
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-sm-5 pr-1">
              <multiselect id="comboPacientes"
                v-model="pacienteSelected"
                :options="pacientes"
                :loading="isLoadingComboPacientes"
                :internal-search="false"
                placeholder=""
                open-direction="bottom"
                label="full_name" track-by="id"
                select-label=""
                deselect-label="X"
                @open="obtenerListaPacientes('')"
                @search-change="busquedaAsincronaPacientes"
                @input="searchPacienteAndShow"
              >
                <span slot="caret" v-if="!(pacienteSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
                <template slot="option" slot-scope="props">
                  {{ props.option.ci }} - {{ props.option.full_name }}
                </template>
                <template slot="afterList">
                  <div class="row">
                    <a
                      v-if="showCargarMas"
                      href="javascript:void(0)"
                      class="col-sm-12 pl-5 py-1 text-dark font-weight-bold"
                      @click="obtenerListaPacientes(null)">
                      Cargar mas...
                    </a>
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="col-sm-1">
              <button
                @click="openModalPaciente"
                class="btn btn-success pr-3">
                +
              </button>
              <ModalPacienteNuevo />
            </div>
            <div class="col-sm-2 text-right px-1">
              <label class="col-form-label">Forma de Pago:
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-sm-2 pl-1 pr-0 text-center">
              <multiselect id="comboFormaPago"
                v-model="formaPagoSelected"
                :options="listaFormaPago"
                placeholder=""
                open-direction="bottom"
                label="name" track-by="id"
                select-label=""
                deselect-label="X"
              >
                <span slot="caret" v-if="!(formaPagoSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
                <template slot="singleLabel" slot-scope="props">
                  <small>{{props.option.name}}</small>
                </template>
                <template slot="option" slot-scope="props">
                  <small>{{props.option.name}}</small>
                </template>
              </multiselect>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 text-right">
              <label class="col-form-label">
                Contacto:
              </label>
            </div>
            <div class="col-sm-6 pr-0">
              <input id="inputContacto"
                type="text"
                v-model="contacto"
                class="form-control"
                autocomplete="off"
              >
            </div>
            <div class="col-sm-2 text-right px-1">
              <label class="col-form-label">Validez:
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-sm-2 pl-1 pr-0 text-center">
              <multiselect id="comboValidez"
                v-model="validezSelected"
                :options="listaValidez"
                placeholder=""
                open-direction="bottom"
                label="dias" track-by="id"
                select-label=""
                deselect-label="X"
              >
                <span slot="caret" v-if="!(validezSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
                <template slot="singleLabel" slot-scope="props">
                  <small>{{props.option.dias}}</small>
                </template>
                <template slot="option" slot-scope="props">
                  <small>{{props.option.dias}}</small>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="row">
            <div class="col-sm-4 pt-2">
              <div class="row">
                <div class="col-sm-10 offset-sm-2">
                  <input id="checkSeguroMedico"
                    class="form-check-input"
                    type="checkbox"
                    :disabled="modoEdicion"
                    v-model="checkSeguroEstado"
                    @click="onChangeSeguroMedico"
                  >
                  <label class="form-check-label" for="checkSeguroMedico">
                    Seguro Medico
                  </label>
                </div>
                <div class="col-sm-12 mt-3 pt-1">
                  <multiselect id="comboAseguradora"
                    v-model="aseguradoraSelected"
                    :disabled="!checkSeguroEstado || modoEdicion"
                    :options="listaAseguradoras"
                    @input="onChangeComboAseguradora"
                    placeholder=""
                    open-direction="bottom"
                    label="nombre" track-by="id"
                    select-label=""
                    deselect-label="X"
                  >
                    <span slot="caret" v-if="!(aseguradoraSelected===null)"></span>
                    <span slot="noResult">Sin Resultados</span>
                    <span slot="noOptions">Lista Vacia</span>
                    <template slot="singleLabel" slot-scope="props">
                      <small>{{props.option.nombre}}</small>
                    </template>
                    <template slot="option" slot-scope="props">
                      <small>{{props.option.nombre}}</small>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-sm-3 text-right pl-0">
                  <label class="col-form-label"><b>Moneda:</b></label>
                </div>
                <div class="col-sm-6 pl-0">
                  <multiselect id="comboMonedas"
                    v-model="monedaSelected"
                    :options="listaMonedas"
                    :disabled="modoEdicion"
                    @input="onChangeMoneda"
                    placeholder=""
                    :allow-empty="false"
                    :searchable="false"
                    label="name" track-by="id"
                    select-label="" deselect-label="X">
                    <span slot="caret" v-if="!(monedaSelected===null)"></span>
                    <template slot="singleLabel" slot-scope="props">
                      <small>{{props.option.name}}</small>
                    </template>
                    <template slot="option" slot-scope="props">
                      <small>{{props.option.name}}</small>
                    </template>
                  </multiselect>
                </div>
                <div class="col-sm-3 pl-0">
                  <input id="inputTipoCambio"
                    type="text"
                    v-model="tipoCambio"
                    @change="resetearCotizacion"
                    class="form-control"
                    autocomplete="off"
                    placeholder="T/C"
                    :disabled="isTipoCambioEnabled"
                  >
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-sm-3 text-right pl-0">
                  <label class="col-form-label">
                    <b>Medico:</b>
                  </label>
                </div>
                <div class="col-sm-9 pl-0">
                  <multiselect id="comboMedicos"
                    v-model="medicoSelected"
                    :options="listaMedicos"
                    :disabled="modoEdicion"
                    placeholder=""
                    label="nombre"
                    track-by="id"
                    select-label=""
                    deselect-label="X">
                    <span slot="caret" v-if="!(medicoSelected===null)"></span>
                    <span slot="noResult">Sin Resultados</span>
                    <template slot="singleLabel" slot-scope="props">
                      <small>{{props.option.nombre}}</small>
                    </template>
                    <template slot="option" slot-scope="props">
                      <small>{{props.option.nombre}}</small>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ModalPacienteNuevo from './TheModalPacienteNuevo.vue';

export default {
  name: 'FormularioCotizacion',
  components: {
    Multiselect,
    DatePicker,
    ModalPacienteNuevo,
  },
  data() {
    return {
      delayTimeout: null,
    };
  },
  methods: {
    ...mapActions('cotizacionAPIS', [
      'obtenerListaPacientes',
      'getDatosPacienteSelected',
    ]),
    ...mapActions('tablaServicios', [
      'resetTablaServicios',
    ]),
    ...mapActions('modalServicios', [
      'resetDatosModalServicios',
    ]),
    resetearCotizacion() {
      this.resetTablaServicios();
      this.resetDatosModalServicios();
    },
    busquedaAsincronaPacientes(query) {
      this.temporaPageComboPacientes = 1;
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(() => this.obtenerListaPacientes(query), DELAY);
    },
    onChangeEstadoFactura() {
      this.resetearCotizacion();
    },
    searchPacienteAndShow() {
      if (this.pacienteSelected === null) {
        this.showCortinaInformativa = false;
        return;
      }
      this.showCortinaInformativa = true;
      this.getDatosPacienteSelected(this.pacienteSelected.id);
    },
    openModalPaciente() {
      this.$bvModal.show('modal-nuevoPaciente');
    },
    onChangeSeguroMedico() {
      this.resetearCotizacion();
    },
    onChangeComboAseguradora() {
      this.resetearCotizacion();
    },
    actualizarTipoCambio() {
      const { tc } = this.monedaSelected;
      if (tc === 0) {
        this.tipoCambio = null;
      } else {
        this.tipoCambio = tc;
      }
    },
    onChangeMoneda() {
      this.actualizarTipoCambio();
      this.resetearCotizacion();
    },
  },
  computed: {
    ...mapFields('formularioCotizacion', [
      'modoEdicion',
      'showCortinaInformativa',
      'estado',
      'datepicker',
      'pacienteSelected',
      'pacientes',
      'isLoadingComboPacientes',
      'temporaPageComboPacientes',
      'totalSearchPacientes',
      'contacto',
      'validezSelected',
      'listaValidez',
      'aseguradoraSelected',
      'listaAseguradoras',
      'formaPagoSelected',
      'listaFormaPago',
      'monedaSelected',
      'listaMonedas',
      'tipoCambio',
      'medicoSelected',
      'listaMedicos',
      'checkSeguroEstado',
    ]),
    labelActivos() {
      if (this.estado) {
        return 'Activo';
      }
      return 'Inactivo';
    },
    showCargarMas() {
      const isOkToSearch = this.temporaPageComboPacientes * 100 < this.totalSearchPacientes;
      return this.totalSearchPacientes > 100 && isOkToSearch;
    },
    isTipoCambioEnabled() {
      return (this.tipoCambio === null || this.modoEdicion);
    },
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
