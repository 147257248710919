<template>
  <b-modal id="modal-nuevoPaciente"
    size='lg'
    title="Nuevo Paciente"
    @ok="validarYGuardar">
    <div class="row mb-2 mx-1">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>Nombres:
                <span class="text-danger">*</span>
              </label>
              <input type="text"
                class="form-control"
                v-model="nombres">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>Apellidos:
                <span class="text-danger">*</span>
              </label>
              <input type="text"
                class="form-control"
                v-model="apellidos">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>Email:</label>
              <input type="email"
                class="form-control"
                v-model="email">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>Medico Externo:</label>
              <div class="row">
                <div
                  :class="showInputMedicoExterno? 'col-sm-9 pr-2': 'col-sm-10'">
                  <multiselect v-if="!showInputMedicoExterno"
                    v-model="medicoExternoSelected"
                    :options="medicosExternos"
                    placeholder=""
                    label="nombre" track-by="id"
                    select-label=""
                    deselect-label="X">
                    <span slot="caret" v-if="!(medicoExternoSelected===null)"></span>
                  </multiselect>
                  <input type="text"
                    v-if="showInputMedicoExterno"
                    v-model="newMedicoExterno"
                    class="form-control">
                </div>
                <div v-if="showInputMedicoExterno"
                  class="col-sm-1 pt-2 px-0">
                  <i class="nav-icon i-Close-Window text-danger font-weight-bold align-middle"
                    @click="goBackMedicoExterno"
                    style="cursor: pointer"></i>
                </div>
                <div class="col-sm-2 pl-0">
                  <button
                    v-if="!showInputMedicoExterno"
                    @click="toggleNewMedicoExterno"
                    class="col-sm-12 btn btn-success">
                    +
                  </button>
                  <button
                    v-if="showInputMedicoExterno"
                    @click="guardarNuevoMedicoExterno"
                    class="col-sm-12 btn btn-success px-0">
                    <i class="i-Disk"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-0">
              <label>F. Nacimiento:</label>
              <div class="row">
                <div class="col-sm-5">
                  <date-picker
                    class="col-sm-12 px-0"
                    v-model="fechaNacimiento"
                    lang="es"
                    format="DD/MM/YYYY"
                    type="date"
                    value-type="date"
                    @input="getAge(fechaNacimiento)"
                  ></date-picker>
                </div>
                <div class="col-sm-3 pl-0">
                  <p class="pt-2">{{edadActual}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-0">
              <label>Nacionalidad:</label>
              <div class="row">
                <div class="col-sm-7 pr-0">
                  <multiselect v-model="nacionalidadSelected"
                    :options="paises"
                    :allow-empty="false"
                    :searchable="false"
                    :show-labels="false"
                    placeholder=""
                    label="descripcion" track-by="id"
                    select-label=""
                    deselect-label="X">
                    <span slot="caret" v-if="!(nacionalidadSelected===null)"></span>
                  </multiselect>
                </div>
                <div class="col-sm-4">
                  <multiselect v-model="sexoSelected"
                    :options="sexos"
                    :allow-empty="false"
                    :searchable="false"
                    :show-labels="false"
                    placeholder=""
                    label="nombre" track-by="id"
                    select-label=""
                    deselect-label="X">
                    <span slot="caret" v-if="!(sexoSelected===null)"></span>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-1">
              <label>Celular:
                <span class="text-danger">*</span>
              </label>
              <div class="row">
                <div class="col-sm-3 pr-0">
                  <multiselect v-model="paisSelected"
                    :options="paises"
                    :allow-empty="false"
                    :show-labels="false"
                    :searchable="false"
                    :close-on-select="true"
                    label="descripcion"
                    track-by="descripcion"
                    @input="setCodigoPais">
                    <template slot="singleLabel" slot-scope="props">
                      <img class="option__image"
                        :src="props.option.img"
                        :alt="props.option.descripcion"
                        width="26px"
                      >
                    </template>
                    <template slot="option" slot-scope="props">
                      <img class="option__image"
                        :src="props.option.img"
                        :alt="props.option.descripcion"
                        width="26px"
                      >
                    </template>
                  </multiselect>
                </div>
                <div class="col-sm-4 pr-0 pl-1">
                  <input type="text"
                    class="form-control"
                    :placeholder="'+'+codigoPais"
                    v-model="celular"
                    autocomplete="off"
                    @keydown="validarCaracter"
                    @blur="reValidarCelular"
                  >
                </div>
                <div class="col-sm-4">
                  <input type="text"
                    v-model="numeroCarnet"
                    class="form-control text-center"
                    autocomplete="off"
                    placeholder="C.I."
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>Clinica Externa:</label>
              <div class="row">
                <div
                :class="showInputClinicaExterna? 'col-sm-9 pr-2': 'col-sm-10'">
                  <multiselect v-if="!showInputClinicaExterna"
                    v-model="clinicaExternaSelected"
                    :options="clinicasExternas"
                    placeholder=""
                    label="nombre" track-by="id"
                    select-label=""
                    deselect-label="X">
                    <span slot="caret" v-if="!(clinicaExternaSelected===null)"></span>
                  </multiselect>
                  <input type="text"
                    v-if="showInputClinicaExterna"
                    v-model="newClinicaExterna"
                    class="form-control">
                </div>
                <div v-if="showInputClinicaExterna"
                  class="col-sm-1 pt-2 px-0">
                  <i class="nav-icon i-Close-Window text-danger font-weight-bold align-middle"
                    @click="goBackClinicaExterna"
                    style="cursor: pointer"></i>
                </div>
                <div class="col-sm-2 pl-0">
                  <button
                    v-if="!showInputClinicaExterna"
                    @click="toggleNewClinicaExterna"
                    class="col-sm-12 btn btn-success">
                    +
                  </button>
                  <button
                    v-if="showInputClinicaExterna"
                    @click="guardarNuevaClinicaExterna"
                    class="col-sm-12 btn btn-success px-0">
                    <i class="i-Disk"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2 mx-1">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>Direccion:</label>
              <input
                type="text"
                class="form-control"
                v-model="direccion"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row border border-success mx-1 mt-3 py-2">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>Nombre Nit:</label>
              <input type="text"
                v-model="nombreNit"
                class="form-control">
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>Nit/CI:</label>
              <input type="text"
                v-model="nitCI"
                class="form-control"
                @keydown="validarCaracter"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <button class="btn btn-outline-dark" @click="cancel()">
        Cancelar
      </button>
      <button class="btn btn-warning" @click="ok()">
        Guardar
      </button>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import { mapFields } from 'vuex-map-fields';
import moment from 'moment';
import { mapActions } from 'vuex';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'NuevoPacienteModal',
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      nombres: '',
      apellidos: '',
      email: '',
      medicoExternoSelected: null,
      medicosExternos: [],
      fechaNacimiento: new Date(),
      edadActual: null,
      nacionalidadSelected: null,
      paises: [],
      sexoSelected: null,
      sexos: [],
      paisSelected: null,
      codigoPais: '',
      celular: '',
      numeroCarnet: '',
      clinicaExternaSelected: null,
      clinicasExternas: [],
      nombreNit: '',
      nitCI: '',
      showInputMedicoExterno: false,
      newMedicoExterno: '',
      showInputClinicaExterna: false,
      newClinicaExterna: '',
      direccion: '',
    };
  },
  created() {
    this.getAge(this.fechaNacimiento);
    this.cargarDatos();
  },
  methods: {
    ...mapActions('cotizacionAPIS', [
      'getDatosPacienteSelected',
    ]),
    getAge(dateString) {
      if (dateString === null) {
        this.edadActual = '';
        return;
      }
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
      }
      this.edadActual = `${age} Años`;
    },
    async cargarDatos() {
      try {
        const RESPONSE = await axios.get('/clinic/invoicing/patient/create');
        this.medicosExternos = RESPONSE.data.data.medico_externos;
        this.clinicasExternas = RESPONSE.data.data.clinica_externos;
        this.paises = RESPONSE.data.data.paises;
        const FIRST_NAC = this.paises[0];
        this.nacionalidadSelected = FIRST_NAC;
        this.sexos = RESPONSE.data.data.sexo;
        const FIRST_GENERO = RESPONSE.data.data.sexo[0];
        this.sexoSelected = FIRST_GENERO;
        this.paisSelected = FIRST_NAC;
        this.codigoPais = this.paisSelected.codigo_telefono;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    setCodigoPais() {
      this.codigoPais = this.paisSelected.codigo_telefono;
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarCelular() {
      if (this.celular === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celular)) {
        this.celular = '';
      }
    },
    toggleNewMedicoExterno() {
      this.showInputMedicoExterno = !this.showInputMedicoExterno;
    },
    toggleNewClinicaExterna() {
      this.showInputClinicaExterna = !this.showInputClinicaExterna;
    },
    goBackMedicoExterno() {
      this.newMedicoExterno = '';
      this.toggleNewMedicoExterno();
    },
    goBackClinicaExterna() {
      this.newClinicaExterna = '';
      this.toggleNewClinicaExterna();
    },
    async listarMedicosExternos() {
      try {
        const RESPONSE = await axios.get('/clinic/doctors/medicoexterno');
        this.medicosExternos = RESPONSE.data.data.medico_externo;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async guardarNuevoMedicoExterno() {
      const REQUEST = { nombre: this.newMedicoExterno };
      try {
        const RESPONSE = await axios.post('/clinic/doctors/medicoexterno', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.newMedicoExterno = '';
        this.listarMedicosExternos();
      } catch (error) {
        const mensajes = error.response.data.data.nombre;
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      }
      this.toggleNewMedicoExterno();
    },
    async listarClinicasExternas() {
      try {
        const RESPONSE = await axios.get('/clinic/doctors/clinicaexterna');
        this.clinicasExternas = RESPONSE.data.data.clinica_externa;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async guardarNuevaClinicaExterna() {
      const REQUEST = { nombre: this.newClinicaExterna };
      try {
        const RESPONSE = await axios.post('/clinic/doctors/clinicaexterna', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.newClinicaExterna = '';
        this.listarClinicasExternas();
      } catch (error) {
        const mensajes = error.response.data.data.nombre;
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      }
      this.toggleNewClinicaExterna();
    },
    validacionOK() {
      this.nombres = this.nombres.trim();
      if (this.nombres === '') {
        util.showNotify('El Campo nombres es requerido', 'warn');
        return false;
      }
      this.apellidos = this.apellidos.trim();
      if (this.apellidos === '') {
        util.showNotify('El Campo apellidos es requerido', 'warn');
        return false;
      }
      if (this.fechaNacimiento === null) {
        util.showNotify('Elije una Fecha Valida', 'warn');
        return false;
      }
      this.celular = this.celular.trim();
      if (this.celular === '') {
        util.showNotify('El Campo celular es requerido', 'warn');
        return false;
      }
      this.numeroCarnet = this.numeroCarnet.trim();
      if (this.numeroCarnet === '') {
        util.showNotify('El Campo C.I. es requerido', 'warn');
        return false;
      }
      return true;
    },
    validarYGuardar(bvModalEvt) {
      if (this.validacionOK() === false) {
        bvModalEvt.preventDefault();
        return;
      }
      let medicoExternoID = null;
      if (this.medicoExternoSelected !== null) {
        medicoExternoID = this.medicoExternoSelected.id;
      }
      let clinicaExternaID = null;
      if (this.clinicaExternaSelected !== null) {
        clinicaExternaID = this.clinicaExternaSelected.id;
      }
      let datoFactura = {
        razon_social: this.nombreNit,
        nit: this.nitCI,
      };
      if (this.nombreNit === '' && this.nitCI === '') {
        datoFactura = null;
      }
      const REQUEST = {
        paciente: {
          nombres: this.nombres,
          apellidos: this.apellidos,
          mail: this.email,
          fecha_nacimiento: moment(this.fechaNacimiento).format('YYYY-MM-DD'),
          nacionalidad_id: this.nacionalidadSelected.id,
          pais_id: this.paisSelected.id,
          telefono: this.celular,
          sexo_id: this.sexoSelected.id,
          ci: this.numeroCarnet,
          medico_externo_id: medicoExternoID,
          clinica_externo_id: clinicaExternaID,
          direccion: this.direccion,
        },
        dato_factura: datoFactura,
      };
      this.guardarDatos(REQUEST);
    },
    limpiarFormulario() {
      this.nombres = '';
      this.apellidos = '';
      this.email = '';
      this.medicoExternoSelected = null;
      this.fechaNacimiento = new Date();
      this.getAge(this.fechaNacimiento);
      const FIRST_NAC = this.paises[0];
      this.nacionalidadSelected = FIRST_NAC;
      const FIRST_GENERO = this.sexos[0];
      this.sexoSelected = FIRST_GENERO;
      this.paisSelected = FIRST_NAC;
      this.codigoPais = this.paisSelected.codigo_telefono;
      this.celular = '';
      this.numeroCarnet = '';
      this.clinicaExternaSelected = null;
      this.nombreNit = '';
      this.nitCI = '';
    },
    async guardarDatos(REQUEST) {
      this.$bvModal.hide('modal-nuevoPaciente');
      try {
        const RESPONSE = await axios.post('/clinic/invoicing/patient', REQUEST);
        this.pacienteSelected = RESPONSE.data.data.paciente;
        this.getDatosPacienteSelected(this.pacienteSelected.id);
        util.showNotify(RESPONSE.data.message, 'success');
        this.limpiarFormulario();
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
      }
    },
  },
  computed: {
    ...mapFields('formularioCotizacion', [
      'pacienteSelected',
    ]),
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
